import React, { Component } from 'react'; //different
//import './style.css';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";
import { json } from 'react-router-dom';
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainsetting: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            stateopterror: "",
            stateregis: 1,
            showpass: true,
            lang: window.localStorage.getItem("lang"),
            bankselect: 0,
            trueaccount: true,
            username: null,
            fname: "",
            checkboxacc: 0,
            userid: null,
            phoneNumber: "",
            userrecomend: "",
            linelink: "",
            balance: 0,
            mainacc: null,
        }
        this.forgetotpandregister = this.forgetotpandregister.bind(this);
    }
    notify = (mode, msg) => {
        Swal.fire({
            title: mode + '!',
            text: msg,
            icon: mode,
            confirmButtonText: 'ตกลง'
        })
        // if (mode == "success") {
        //     toast.success(msg, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //     });
        // }
        // else if (mode == "warning") {
        //     toast.warning(msg, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //     });
        // }
        // else if (mode == "error") {
        //     toast.error(msg, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //     });
        // }
        // else if (mode == "info") {
        //     toast.info(msg, {
        //         position: "top-right",
        //         autoClose: 5000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: "colored",
        //     });
        // }
    }
    confirmdeposit = async (e, num) => {

        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;

        await instance.post("/api/v1/getredeempromotion", {
            userid: Username,
            System: Systems,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    proderedeem: res.data.message.tblpromotionredeems,
                });

            }
        }).catch(async (err) => {
        });

        if (num === 0) {
            if ((this.state.depositamount >= this.state.limit.min) && (this.state.depositamount <= this.state.limit.max)) {
                const { depositstate } = this.state
                ////////console.log(depositstate, num);
                if (num === 0) {
                    if (token != null) {
                        instance.post("/api/v1/getqrcode", {
                            username: Username,
                            System: Systems,
                            amount: this.state.depositamount
                        }, {
                            headers: { Authorization: `Bearer ${token}` }
                        }).then(async (res) => {
                            ////////console.log(res.data.picture)
                            this.setState({
                                mainacc: res.data.mainacc,
                                qrcode: res.data.picture
                            });
                        });
                    }

                    this.setState({
                        depositstate: 1
                    });
                }
            }
            else {
                await Swal.fire({
                    title: 'Warning!',
                    text: 'เติมขั้นต่ำ ' + this.state.limit.min + ' บาท สูงสุด ' + this.state.limit.max + ' ',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
            }
        }
        else if (num === 1) {
            this.setState({
                depositstate: 1
            });
        }
        else {
            this.setState({
                depositstate: 0
            });
        }



    }
    radiocheck(input) {
        console.log(input);
        this.setState({
            bankselect: input
        });
        if (input == 99) {
            this.setState({
                trueaccount: false
            });
        }
        else {
            this.setState({
                trueaccount: true,
                fname: ""
            });
        }
    }
    forgetotpandregister = async (type) => {
        const { phoneNumber, otpphoneforget, bankselect, otpphone, accnumbertxt } = this.state;
        var recheck = false;
        if (type === 4) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 5) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphoneforget,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 6) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;
            });
        }
        else if (type === 7) {
            await instance.post("/api/v1/resetpassword", {
                username: phoneNumber,
                pin: otpphone,
                System: Systems,
                type: type
            }).then(async (res) => {
                recheck = res.data.message;

            });
        }
        else if (type === 10) {
            return await instance.post("/api/v1/resetpassword", {
                accountnumber: accnumbertxt,
                bankid: bankselect,
                System: Systems,
                type: type
            }).then(async (res) => {
                return recheck = res.data.message;

            });
        }
        return recheck
    }
    nextstep = async (e, num) => {
        e.preventDefault();
        const { stateregis, password, bankselect, passwordcf, phoneNumber, checkboxacc, fname, otpphone, accnumbertxt } = this.state
        console.log(5565656, num);
        if (num === 1) {
            const check = await this.forgetotpandregister(6);

            console.log(phoneNumber.length === 10 && check.checked)
            if (phoneNumber.length === 10 && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else {
                if (phoneNumber.length !== 10) {
                    await this.notify("warning", 'คุณใส่เบอร์ผิดพลาด');
                }
                else if (!check.checked && check.status === 201) {
                    await this.notify("warning", 'เบอร์นี้มีคนใช้แล้ว');
                }
                else {
                    await this.notify("warning", 'คุณทำรายการถี่เกินไป โปรดรอซักครู่');
                    this.setState({
                        stateopterror: "คุณทำรายการถี่เกินไป โปรดรอซักครู่"
                    });
                }
            }
        }
        else if (num === 2) {

            if (password !== passwordcf) {
                await this.notify("warning", 'คุณไส่พาสเวิดไม่ตรงกัน');
            }
            else {
                this.setState({
                    stateregis: 3
                });
            }

        }
        else if (num === 3) {
            if (checkboxacc !== 0 && fname !== "" && accnumbertxt !== "") {
                this.setState({
                    stateregis: 3
                });
            }
            else {
                await this.notify("warning", 'กรุณาเลือกธนาคาร หรือใส่ข้อมูลให้ครบถ้วน');
                //////console.log(2, stateregis, checkboxacc);
            }
        }
        else if (num === 5) {
            var check = await this.forgetotpandregister(7);
            if (otpphone !== "" && check.checked) {
                this.setState({
                    stateregis: 2
                });
            }
            else if (otpphone === "" || otpphone.length < 4) {
                await this.notify("warning", 'คุณใส่ตัวเลขไม่ครบ');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }

            else {
                await this.notify("warning", 'รหัสผิดพลาด');
                document.getElementById("otpphone").style = "border-color: #ef4444;";
                document.getElementById("checkotp").style.display = "block";
            }



        }
        else if (num === 11) {

            console.log(1, stateregis, num);

            this.setState({
                stateregis: 1
            });
        }
        else if (num === 22) {
            //////console.log(1, stateregis, num);
            this.setState({
                stateregis: 2
            });
        }
        else if (num === 25) {
            if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
                var title = 'error'
                var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
                var object = { title, text }
                this.modalerror(object);

            }
            else if (accnumbertxt.length < 10) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else if (bankselect === 0) {
                var title = 'error'
                var text = 'คุณยังไม่ได้เลือกธนาคาร'
                var object = { title, text }
                this.modalerror(object);
            }
            else {
                this.setState({
                    loading: true,
                });
                if (fname === '') {
                    let check = await this.forgetotpandregister(10)
                    if (check.checked) {
                        console.log(302, check.verifications);
                        console.log(302, check.verifications.name);
                        if (check.verifications.status == 0) {
                            this.setState({
                                trueaccount: false,
                            });
                        }
                        else if (check.verifications.status == 1) {
                            this.setState({
                                fname: check.verifications.name,
                            });
                            await this.validatergisterForm(e, check.verifications.name);
                        }
                    }
                    else {
                        var title = 'error'
                        var text = 'เลขบัญชีไม่ถูกต้อง'
                        var object = { title, text }
                        this.modalerror(object);
                    }
                }
                else {
                    await this.validatergisterForm(e, fname)
                }
                this.setState({
                    loading: false,
                });
                //console.log(302, check.verifications);
            }


        }
    };

    onChangex = event => {
        // console.log("event", event.target.name);


        if (event.target !== undefined) {
            let nam;
            let val;
            if (event.target.name === "otpphone") {
                document.getElementById("otpphone").style = "";
                document.getElementById("checkotp").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("otpphoneforget").style = "";
                document.getElementById("checkotpforget").style.display = "none";
            }
            else if (event.target.name === "otpphoneforget") {
                document.getElementById("phoneNumber").style = "";
                document.getElementById("checkotpforgetphonenormal").style.display = "none";
            }

            if (event.target.name === undefined) {
                nam = event.name;
                val = event.value;
            } else {
                nam = event.target.name;
                val = event.target.value;
            }
            this.setState({ [nam]: val });
        }
    };
    async modalerror(object) {
        // await Swal.fire({
        //     title: object.title + "!",
        //     text: object.text,
        //     icon: object.title,
        //     confirmButtonText: 'ตกลง'
        // })
        await this.notify(object.title, object.text);
    }
    validatergisterForm = async (e, fname) => {
        e.preventDefault();
        const { phoneNumber, password, passwordcf, bankselect, accnumbertxt } = this.state;
        console.log(bankselect);
        console.log(this.state)
        if (accnumbertxt === "" || typeof accnumbertxt == "undefined") {
            var title = 'error'
            var text = 'คุณไม่ทำการใส่เลขบัญชีธนาคาร'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (fname === "") {

            var title = 'error'
            var text = 'คุณไม่ทำการใส่ ชื่อ-นามสกุล'
            var object = { title, text }
            this.modalerror(object);

        }
        else if (password.length > 15) {
            var title = 'error'
            var text = 'พาสเวิดสูงสุดไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password.length < 6) {
            var title = 'error'
            var text = 'พาสเวิดต่ำไม่เกิน 15 ตัว'
            var object = { title, text }
            this.modalerror(object);
        }
        else if (password != passwordcf) {
            var title = 'error'
            var text = 'รหัสยิืนยันไม่ตรงกัน'
            var object = { title, text }
            this.modalerror(object);
        }
        else {
            await instance.post("/api/v1/register", {
                User_id: phoneNumber,
                name: fname,
                password: password,
                accbank: bankselect,
                accno: accnumbertxt,
                System: Systems,
                Useradvise: this.state.userrecomend
            }).then(async (res) => {
                console.log(res.data);
                if (res.data.status === 200) {
                    var title = 'success'
                    var text = 'ทำการสมัครเรียบร้อย กรุณารอซักครู่'
                    var object = { title, text }
                    await this.modalerror(object);


                    await instance.post("/api/v1/userlogin", {
                        userid: phoneNumber,
                        password: password,
                        System: Systems,
                        Function: 'validateLoginForm-navbar.js'
                    }).then((res) => {
                        if (res.status === 200) {
                            if (res.data.status === 200) {
                                localStorage.setItem('auth_token', res.data.token);
                                setInterval(() =>
                                    window.location.href = '/'
                                    , 4000);

                            }
                        }
                    });
                }
                else if (res.data.status === 203) {
                    var title = 'error'
                    var text = 'เลขบัญชี นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else if (res.data.status === 202) {
                    var title = 'error'
                    var text = 'Username นี้ทำการสมัครไปแล้ว'
                    var object = { title, text }
                    this.modalerror(object);
                }
                else {
                    var title = 'error'
                    var text = 'Username หรือ ชื่อในการสมัครไม่ถูกต้อง'
                    var object = { title, text }
                    this.modalerror(object);
                }
            });
        }


    };
    changepass = (e) => {
        e.preventDefault();
        this.setState({
            showpass: this.state.showpass ? false : true,
        });
    }
    async saveclickaff(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                this.setState({
                    userrecomend: res.data.username,
                    link: linkid
                });
                var username = res.data.username
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclickaff", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
            if (res.data.status == 200) {
                var date = new Date()
                var username = res.data.username
                this.setState({
                    userrecomend: username,
                });
                var object = { date, username }
                localStorage.setItem('aff', JSON.stringify(object))
            }
        }).catch(async (err) => {
        });

    }
    async componentDidMount() {
        // var token = localStorage.getItem('auth_token');
        // if (token != null) {
        //     localStorage.removeItem('auth_token', '')
        // }

        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (test != undefined) {
            this.saveclickaff(test.split("&")[0]);
        }
        var params = search.split("reg=")[1]
        if (params != undefined) {
            this.saveclick(params);
            this.setState({
                userrecomend: params.split("&")[0],
            });
        }

        var tokenf = localStorage.getItem('aff');
        if (tokenf != null) {
            var codev = JSON.parse(tokenf)
            //   console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }

        var tokenx = localStorage.getItem('register');
        if (tokenx != null) {
            var codev = JSON.parse(tokenx)
            // console.log(codev);
            var Username = codev.username;
            this.setState({
                userrecomend: Username,
            });
        }


        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var limit = { min, max }
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System,
                    limit: limit
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        //  this.nameInput.focus();
    }





    render() {

        const { stateopterror, userrecomend } = this.state

        //console.log(prodetail.length)
        return (
            <>
                <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                    {/**/}
                </div>
                <div className="-games-index-page col-12">
                    <div className="mb-2 text-white-v1">
                        <div className="d-none d-lg-block">
                        </div>
                        {/**/}
                        <div className="padding-router-view">
                            <div className="">
                                <div className=" m-3">
                                    <div className="container">
                                        <div className="row">
                                            <div className="pt-5 text-white-v1 text-center col col-sm-8 col-md-10 col-lg-8 col-xl-5 pl-0 pr-0 mx-auto my-auto">

                                                <br />
                                                <div className="x-modal-separator-container x-login-form  bg-signIn neon-auth-box">
                                                    <div className="-top">
                                                        <div data-animatable="fadeInModal" className="animated fadeInModal"  >
                                                            <div className="text-font-title-signin text-white-v1 mt-2 pt-2">
                                                                <img
                                                                    src="https://cdn.javisx.com/image/61fecf50-99ec-498e-9508-3080597757f8.png"
                                                                    className="logo-w-signIn-v113"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="-img-container text-center">
                                                            </div>
                                                            {/**/}
                                                            <form autocomplete="new-password" style={{ marginTop: "-10px" }}>
                                                                <div className="x-modal-separator-container x-login-form pb-3  bg-signup">
                                                                    <div className="-top" style={{ display: this.state.stateregis === 1 ? "" : "none" }}>
                                                                        <div className="-fake-inner-body">
                                                                            <div className="x-step-register">
                                                                                <div className="px-0 m-auto -container-wrapper">
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer" />
                                                                                    <div className="-step-box-outer" />
                                                                                    <div className="-step-box-outer" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="-img-container text-white text-center">
                                                                                    <h6> <span id='menu183'>สมัครสมาชิกด้วยเบอร์โทรศัพท์</span> </h6>
                                                                                </div>
                                                                                <div className="-x-input-icon mb-1 text-center text-center">
                                                                                    <div className="input-group mb-2">
                                                                                        <span
                                                                                            className="input-group-text bg-border-input-icon"
                                                                                            id="basic-addon1"
                                                                                        >
                                                                                            <img className="-icon"
                                                                                                alt="EZ Slot"
                                                                                                width={15}
                                                                                                height={15}
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/c8d943f5-025b-4bee-ee2e-752b3e427d00/public" />
                                                                                        </span>
                                                                                        <input
                                                                                            onChange={this.onChangex}
                                                                                            type="text"
                                                                                            id="phoneNumber"
                                                                                            name="phoneNumber"
                                                                                            className="form-control form-control-form bg-border-input"
                                                                                            maxLength={10}
                                                                                            minLength={10}
                                                                                            aria-label="phoneNumber"
                                                                                            placeholder={this.state.lang === "la" ? "20xxxxxxxx" : "086-xxxxxxxx"}  
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <div />
                                                                                <div>
                                                                                    <div className="mt-1 mb-2">
                                                                                        <div>
                                                                                            <button
                                                                                                ref={(input) => { this.nameInput = input; }}
                                                                                                onClick={(e) => {
                                                                                                    this.nextstep(e, 1)
                                                                                                }}
                                                                                                className="btn -submit js-submit-accept-term rounded-pill v-login mt-lg-1 mt-0 w-100 p-2">
                                                                                                <span><span id='menu184'>ถัดไป</span> </span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mt-1 mb-1 w-100">
                                                                                    <h3 className="title-footer-wrap pt-2">
                                                                                        <b>Language / <span id='menu185'>ภาษา</span>  </b>
                                                                                    </h3>
                                                                                    <ul className="logo-list-footer">
                                                                                        <li role="button" onClick={(e) => {
                                                                                            localStorage.setItem('lang', "th")
                                                                                            this.setState({
                                                                                                lang: "th",
                                                                                            })
                                                                                            window.location.href = "/"
                                                                                        }}>
                                                                                            <img
                                                                                                className={this.state.lang === "th" ? "imgBankNotSelect" : "imgBank"}
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                        <li role="button" onClick={(e) => {
                                                                                            localStorage.setItem('lang', "la")
                                                                                            this.setState({
                                                                                                lang: "la",
                                                                                            })
                                                                                            window.location.href = "/"
                                                                                        }}>
                                                                                            <img

                                                                                                className={this.state.lang  === "la" ? "imgBankNotSelect" : "imgBank"}
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7bd877b2-2dd7-45e8-3246-b38ea4d77f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-top" style={{ display: this.state.stateregis === 2 ? "" : "none" }}>
                                                                        <div className="-fake-inner-body">
                                                                            <div className="x-step-register">
                                                                                <div className="px-0 m-auto -container-wrapper">
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="-img-container text-center" style={{ color: "white" }}>
                                                                                    <h6><span id='menu186'>รหัสผ่าน</span> </h6>
                                                                                </div>
                                                                                <div className="mb-1 text-center">
                                                                                    <div className="-x-input-icon flex-column">
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text bg-border-input-icon">
                                                                                                <img
                                                                                                    className="-icon"
                                                                                                    alt="EZ Slot"
                                                                                                    width={15}
                                                                                                    height={15}
                                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/bb8fe716-5b2a-4b92-60ff-7608db842e00/public"
                                                                                                />
                                                                                            </span>
                                                                                            <input
                                                                                                onChange={this.onChangex}
                                                                                                type={!this.state.showpass ? "text" : "password"}
                                                                                                id="password"
                                                                                                name="password"
                                                                                                className="form-control form-control-form border-r-0"
                                                                                                placeholder="password"
                                                                                            />
                                                                                            <span
                                                                                                className="input-group-text bg-border-input-icon-eye"
                                                                                                id="change-eye"
                                                                                                role="button"
                                                                                                onClick={(e) => this.changepass(e)}
                                                                                            >
                                                                                                <svg
                                                                                                    style={{ display: (!this.state.showpass) ? "none" : "" }}
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-eye-slash"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                                                </svg>

                                                                                                <svg
                                                                                                    style={{ display: (this.state.showpass) ? "none" : "" }}
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-eye"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                                                </svg>

                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text bg-border-input-icon">
                                                                                                <img
                                                                                                    className="-icon"
                                                                                                    alt="EZ Slot"
                                                                                                    width={15}
                                                                                                    height={15}
                                                                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/bb8fe716-5b2a-4b92-60ff-7608db842e00/public"
                                                                                                />
                                                                                            </span>
                                                                                            <input
                                                                                                onChange={this.onChangex}
                                                                                                type={!this.state.showpass ? "text" : "password"}
                                                                                                id="passwordcf"
                                                                                                name="passwordcf"
                                                                                                className="form-control form-control-form border-r-0"
                                                                                                placeholder="password"
                                                                                            />
                                                                                            <span
                                                                                                className="input-group-text bg-border-input-icon-eye"
                                                                                                id="change-eye"
                                                                                                role="button"
                                                                                                onClick={(e) => this.changepass(e)}
                                                                                            >
                                                                                                <svg
                                                                                                    style={{ display: (!this.state.showpass) ? "none" : "" }}
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-eye-slash"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                                                </svg>

                                                                                                <svg
                                                                                                    style={{ display: (this.state.showpass) ? "none" : "" }}
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-eye"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                                                </svg>

                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <div className="m-auto -term-and-condition-check-box">
                                                                                    <div className="x-checkbox-primary d-flex justify-content-center">
                                                                                        <a
                                                                                            onClick={(e) => {
                                                                                                this.nextstep(e, 11)
                                                                                            }}
                                                                                            role="button" className="text-white-v1">
                                                                                            <span id='menu187'>ยกเลิก</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                <div />
                                                                                <div>
                                                                                    <div className="mt-1 mb-2">
                                                                                        <div>
                                                                                            <button
                                                                                                onClick={(e) => {
                                                                                                    this.nextstep(e, 2)
                                                                                                }}
                                                                                                className="btn -submit js-submit-accept-term rounded-pill v-login mt-lg-1 mt-0 w-100 p-2">
                                                                                                <span>    <span id='menu188'>ถัดไป</span></span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mt-1 mb-1 w-100">
                                                                                    <h3 className="title-footer-wrap pt-2">
                                                                                        <b>Language / <span id='menu189'>ภาษา</span> </b>
                                                                                    </h3>
                                                                                    <ul className="logo-list-footer">
                                                                                        <li role="button">
                                                                                            <img
                                                                                                className="lang-img active-lang"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                        <li role="button">
                                                                                            <img
                                                                                                className="lang-img"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7bd877b2-2dd7-45e8-3246-b38ea4d77f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="-top" style={{ display: this.state.stateregis === 3 ? "" : "none" }}>
                                                                        <div className="-fake-inner-body">
                                                                            <div className="x-step-register">
                                                                                <div className="px-0 m-auto -container-wrapper">
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer step-active" />
                                                                                    <div className="-step-box-outer step-active" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="-img-container text-center" style={{ color: "white" }}>
                                                                                    <h6><span id='menu190'>กรุณาเลือกธนาคาร</span></h6>
                                                                                </div>
                                                                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
                                                                                    <div className="inputPaddingRegisterTop">
                                                                                        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 css-tuxzvu">

                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(77)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 77 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/7a812503-c004-43f0-8a8f-53fa7c8370ae.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(78)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 78 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/ae34718a-c628-4807-927f-fd6286c72727.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(79)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 79 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/96e0e525-2187-405d-b4f4-ac6d728cb040.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(80)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 80 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/f24de48a-4f3b-4fd8-b4f7-5c1aebd4b9e3.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(81)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 81 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/3d14057d-2e8d-4ecc-a4bf-1949e7759926.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(82)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 82 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/d8d6b1a5-4c1c-4b1f-8247-67e7227edd8a.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(83)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 83 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/e924b0bb-4e30-468e-85d4-35f45aa315d7.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(84)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 84 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/7c0a8c60-7a45-4e86-a8ba-50b2f1386541.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(85)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 85 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/6ebe6732-ae80-453b-bcd6-ead76b18c8a4.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(86)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 86 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/136de213-0066-4710-b457-4d1d906a572d.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                            <a className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-2 css-o0rlmm"
                                                                                                onClick={(e) => {
                                                                                                    this.radiocheck(87)
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    className={this.state.bankselect == 87 ? "imgBankNotSelect" : "imgBank"}
                                                                                                    src="https://cdn.javisx.com/image/84c227cd-85d7-42ef-9ed6-1a6942a86cff.png"
                                                                                                    alt="bank list"
                                                                                                />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="input-group mt-2">
                                                                                    <span
                                                                                        className="input-group-text bg-border-input-icon"
                                                                                        id="basic-addon1"
                                                                                    >
                                                                                        <img
                                                                                            className="-icon"
                                                                                            alt="EZ Slot"
                                                                                            width={15}
                                                                                            height={15}
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/bb8fe716-5b2a-4b92-60ff-7608db842e00/public"
                                                                                        />
                                                                                    </span>
                                                                                    <input
                                                                                        onChange={this.onChangex}
                                                                                        id="accnumbertxt"
                                                                                        inputMode="text"
                                                                                        name="accnumbertxt"
                                                                                        required="required"
                                                                                        className="form-control form-control-form bg-border-input"
                                                                                        aria-label="member_accid"
                                                                                        placeholder= {this.state.lang === "la" ? "ເລກບັນຊີ" : "เลขบัญชี"}  
                                                                                    />
                                                                                </div>

                                                                                <div className="input-group mt-2" style={{ display: this.state.trueaccount ? "none" : "" }}>
                                                                                    <span
                                                                                        className="input-group-text bg-border-input-icon"
                                                                                        id="basic-addon1"
                                                                                    >
                                                                                        <img
                                                                                            className="-icon"
                                                                                            alt="EZ Slot"
                                                                                            width={15}
                                                                                            height={15}
                                                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/84f8435d-3a19-4e45-eb3c-57f582263300/public"
                                                                                        />
                                                                                    </span>
                                                                                    <input
                                                                                        type="text"
                                                                                        onChange={this.onChangex}
                                                                                        id="fname"
                                                                                        value={this.state.fname}
                                                                                        name="fname"
                                                                                        required="required"
                                                                                        className="form-control form-control-form bg-border-input"
                                                                                        aria-label="member_accid"
                                                                                        placeholder= {this.state.lang === "la" ? "ຊື່ບັນຊີ" : "ชื่อบัญชี"}  
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="text-center">
                                                                                <div className="m-auto -term-and-condition-check-box">
                                                                                    <div className="x-checkbox-primary d-flex justify-content-center">
                                                                                        <a
                                                                                            onClick={(e) => {
                                                                                                this.nextstep(e, 11)
                                                                                            }}
                                                                                            role="button" className="text-white-v1">

                                                                                            <span id='menu191'>ยกเลิก</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                <div />
                                                                                <div>
                                                                                    <div>
                                                                                        <a
                                                                                            onClick={(e) => {
                                                                                                this.nextstep(e, 25)
                                                                                            }}
                                                                                            disabled={this.state.loading}
                                                                                            type="submit"
                                                                                            role="button"
                                                                                            className="btn -submit js-submit-accept-term rounded-pill v-login mt-lg-1 mt-0 w-100 p-2"
                                                                                        >
                                                                                            <div style={{ display: this.state.loading ? "" : "none" }}>
                                                                                                <span className="spinner-border" role="status">
                                                                                                    <span className="visually-hidden">Loading...</span>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div style={{ display: this.state.loading ? "none" : "" }}>

                                                                                                <span id='menu192'>ยืนยัน</span>
                                                                                            </div>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mt-1 mb-1 w-100">
                                                                                    <h3 className="title-footer-wrap pt-2">
                                                                                        <b>Language /   <span id='menu193'>ภาษา</span></b>
                                                                                    </h3>
                                                                                    <ul className="logo-list-footer">
                                                                                        <li role="button">
                                                                                            <img
                                                                                                className="lang-img active-lang"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                        <li role="button">
                                                                                            <img
                                                                                                className="lang-img"
                                                                                                src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/7bd877b2-2dd7-45e8-3246-b38ea4d77f00/public"
                                                                                                alt=""
                                                                                            />
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="mt-3">
                                                                <div className="row m-0">
                                                                    <div className="col-6 col-md-6">
                                                                        <button className="btn -submit js-submit-accept-term rounded-pill v-login mt-lg-1 mt-0 w-100 p-2"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                window.location.href = '/signin';
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={15}
                                                                                height={15}
                                                                                fill="currentColor"
                                                                                className="bi bi-person-fill"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                                            </svg>
                                                                            <span><span id='menu194'>ภาษา</span></span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-6 col-md-6">
                                                                        <a href={this.state.linelink} className="btn -submit js-submit-accept-term btn-success v-login-contact mt-lg-1 mt-0 w-100 p-2">
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={15}
                                                                                height={15}
                                                                                fill="currentColor"
                                                                                className="bi bi-telephone-fill"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                                                                                />
                                                                            </svg>
                                                                            <span><span id='menu195'>ภาษา</span></span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default App;
